// import antd from 'ant-design-vue/es/locale-provider/es_ES'
import momentES from 'moment/locale/es'
import global from './es-ES/global'

import menu from './es-ES/menu'
import setting from './es-ES/setting'
import user from './es-ES/user'
import dashboard from './es-ES/dashboard'
import result from './es-ES/result'
import account from './es-ES/account'
import permission from './es-ES/permission'
import datum from './es-ES/datum'
import consumer from './es-ES/consumer'
import system from './es-ES/system'
import order from './es-ES/order'

const components = {
  // antLocale: antd,
  momentName: 'es-ES',
  momentLocale: momentES
}

export default {
  message: '-',
  'layouts.usermenu.dialog.title': '信息',
  'layouts.usermenu.dialog.content': '您确定要注销吗？',
  'layouts.userLayout.title': 'Ant Design 是西湖区最具影响力的 Web 设计规范',
  ...components,
  ...global,
  ...menu,
  ...setting,
  ...user,
  ...dashboard,
  ...result,
  ...account,
  ...permission,
  ...datum,
  ...consumer,
  ...system,
  ...order
}
