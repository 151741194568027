export default {
  'app.setting.pagestyle': 'Estilo de página',
  'app.setting.pagestyle.light': 'Estilo claro',
  'app.setting.pagestyle.dark': 'Estilo oscuro',
  'app.setting.pagestyle.realdark': 'Estilo oscuro real',
  'app.setting.themecolor': 'Color del tema',
  'app.setting.navigationmode': 'Modo de navegación',
  'app.setting.content-width': 'Ancho del contenido',
  'app.setting.fixedheader': 'Encabezado fijo',
  'app.setting.fixedsidebar': 'Barra lateral fija',
  'app.setting.sidemenu': 'Menú lateral',
  'app.setting.topmenu': 'Menú superior',
  'app.setting.content-width.fixed': 'Fijo',
  'app.setting.content-width.fluid': 'Fluido',
  'app.setting.othersettings': 'Otras configuraciones',
  'app.setting.weakmode': 'Modo débil',
  'app.setting.copy': 'Configuración de copia',
  'app.setting.loading': 'Cargando la configuración',
  'app.setting.copyinfo':
    'La configuración de copia se realizó correctamente src/config/defaultSettings.js',
  'app.setting.production.hint':
    'La barra de configuración solo se usa para la vista previa en el entorno de desarrollo y no se mostrará en el entorno de producción. Copie y modifique el archivo de configuración manualmente.',
  'app.setting.themecolor.daybreak': 'Amanecer',
  'app.setting.themecolor.dust': 'Polvo',
  'app.setting.themecolor.volcano': 'Volcán',
  'app.setting.themecolor.sunset': 'Puesta de sol',
  'app.setting.themecolor.cyan': 'Cian',
  'app.setting.themecolor.green': 'Verde',
  'app.setting.themecolor.geekblue': 'Azul geek',
  'app.setting.themecolor.purple': 'Púrpura'
}
