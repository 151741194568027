export default {
  'result.success.title': 'Enviado con éxito',
  'result.success.description':
    'La página de resultados del envío se utiliza para enviar comentarios sobre los resultados del procesamiento de una serie de tareas de operación. Si solo se trata de una operación simple, utilice los comentarios de mensajes globales. Esta área de texto puede mostrar instrucciones complementarias simples. Si es necesario mostrar "recibos", el área gris a continuación puede mostrar contenido más complejo.',
  'result.success.operate-title': 'El proyecto se ha enviado a revisión',
  'result.success.operate-id': 'ID de proyecto',
  'result.success.principal': 'Persona responsable',
  'result.success.operate-time': 'Tiempo de revisión',
  'result.success.step1-title': 'Revisión de la gerencia',
  'result.success.step1-operator': 'Zhou Maomao',
  'result.success.step2-title': 'Revisión de la gerencia',
  'result.success.step2-operator': 'Zhou Maomao',
  'result.success.step2-extra': 'Urgente',
  'result.success.step3-title': 'Revisión de la gerencia',
  'result.success.step4-title': 'Revisión de la gerencia',
  'result.success.btn-return': 'Volver a la lista',
  'result.success.btn-project': 'Ver proyecto',
  'result.success.btn-print': 'Imprimir',
  'result.fail.error.title': 'No se ha enviado con éxito',
  'result.fail.error.description':
    'La descripción de la falla del envío se muestra a continuación y se recomienda que utilice el botón de retorno para volver a la página anterior.',
  'result.fail.error.hint-title': 'La información suministrada no es válida',
  'result.fail.error.hint-text1': 'Su cuenta ha sido bloqueada',
  'result.fail.error.hint-btn1': 'Desbloquear',
  'result.fail.error.hint-text2': 'Su cuenta está en proceso de verificación',
  'result.fail.error.hint-btn2': 'Verificación en curso',
  'result.fail.error.btn-text': 'Volver a enviar'
}
