export default {
  'consumer.list.userName': 'nombre de usuario',
  'consumer.list.account': 'cuenta',
  'consumer.list.name': 'nombre',
  'consumer.list.phone': 'teléfono',
  'consumer.list.email': 'correo electrónico',
  'consumer.list.createTime': 'tiempo de creación',
  'consumer.list.status': 'estado',
  'consumer.list.birthDay': 'cumpleaños',
  'consumer.list.height': 'altura',
  'consumer.list.weight': 'peso',
  'consumer.list.baseInfo': 'información básica',
  'consumer.list.authority': 'autoridad',
  'consumer.list.sex': 'sexo',
  'consumer.list.isVip': 'es vip',
  'consumer.list.freeze': 'congelar',
  'consumer.list.normal': 'normal',
  'consumer.list.man': 'hombre',
  'consumer.list.woman': 'mujer',
  'consumer.list.medicationHistory': 'historial de medicación',
  'consumer.list.medicationHistoryTime': 'tiempo de medicación',
  'consumer.list.password': 'contraseña',
  'consumer.list.repeatPassword': 'repetir contraseña',
  'consumer.list.active': 'activo',
  'consumer.list.addUser': 'agregar usuario',
  'consumer.list.editUser': 'editar usuario',
  'consumer.list.nameTip': 'por favor ingrese el nombre',
  'consumer.list.heightTip': 'por favor ingrese la altura',
  'consumer.list.heightUnitTip': 'por favor ingrese la unidad de altura',
  'consumer.list.weightTip': 'por favor ingrese el peso',
  'consumer.list.weightUnitTip': 'por favor ingrese la unidad de peso',
  'consumer.list.sexTip': 'por favor seleccione el sexo',
  'consumer.list.birthDayTip': 'por favor seleccione la fecha de nacimiento',
  'consumer.list.medicationHistoryTip':
    'por favor ingrese el historial de medicación',
  'consumer.list.userNameTip': 'por favor ingrese el nombre de usuario',
  'consumer.list.emailTip': 'por favor ingrese el correo electrónico',
  'consumer.list.phoneTip': 'por favor ingrese el teléfono',
  'consumer.list.passwordTip': 'por favor ingrese la contraseña',
  'consumer.list.repeatPasswordTip':
    'por favor ingrese la contraseña nuevamente',
  'consumer.list.authorityPlaceholder': 'por favor seleccione la autoridad',
  'consumer.list.region': 'región',
  'consumer.list.personalStatusTip': 'por favor seleccione el estado personal',
  'consumer.list.none': 'ninguno',
  'consumer.list.hypertension': 'hipertensión',
  'consumer.list.hypotension': 'hipotensión',
  'consumer.list.authorityName': 'nombre de la autoridad',
  'datum.healthData.isVip': 'es vip',
  'consumer.list.userNamePlaceholder': 'por favor ingrese el nombre de usuario',
  'consumer.list.authorityNamePlaceholder':
    'por favor ingrese el nombre de la autoridad',
  'consumer.list.checkData01': 'por favor ingrese el nombre de usuario',
  'consumer.list.checkData02': 'por favor ingrese el nombre de la autoridad',
  'consumer.list.isStatus': 'estado',
  'consumer.list.status01': 'normal',
  'consumer.list.status02': 'congelado',
  'consumer.list.authorityLevel': 'nivel de autoridad'
}
