export default {
  'dashboard.analysis.test': 'Gongzhuan Road {no} tienda',
  'dashboard.analysis.introduce': 'Introduzca',
  'dashboard.analysis.total-sales': 'Ventas Totales',
  'dashboard.analysis.day-sales': 'Ventas del día',
  'dashboard.analysis.visits': 'Visitas',
  'dashboard.analysis.visits-trend': 'Tendencia de visitas',
  'dashboard.analysis.visits-ranking': 'Clasificación de visitas',
  'dashboard.analysis.day-visits': 'Visitas diarias',
  'dashboard.analysis.week': 'Cuota Semanal',
  'dashboard.analysis.day': 'Cuota diaria',
  'dashboard.analysis.payments': 'Pagos',
  'dashboard.analysis.conversion-rate': 'Tasa de Conversión',
  'dashboard.analysis.operational-effect': 'Efecto Operacional',
  'dashboard.analysis.sales-trend': 'Tendencia de Ventas de Camisetas',
  'dashboard.analysis.sales-ranking': 'Clasificación de Ventas',
  'dashboard.analysis.all-year': 'Todo el año',
  'dashboard.analysis.all-month': 'Todo el mes',
  'dashboard.analysis.all-week': 'Toda la semana',
  'dashboard.analysis.all-day': 'Todo el día',
  'dashboard.analysis.search-users': 'Búsqueda de usuarios',
  'dashboard.analysis.per-capita-search': 'Búsqueda Per Capita',
  'dashboard.analysis.online-top-search': 'Búsqueda en línea más popular',
  'dashboard.analysis.the-proportion-of-sales': 'The Proportion Of Sales',
  'dashboard.analysis.dropdown-option-one': 'Opción 1',
  'dashboard.analysis.dropdown-option-two': 'Opción 2',
  'dashboard.analysis.channel.all': 'Todo',
  'dashboard.analysis.channel.online': 'En línea',
  'dashboard.analysis.channel.stores': 'Tiendas',
  'dashboard.analysis.sales': 'Ventas',
  'dashboard.analysis.traffic': 'Tráfico',
  'dashboard.analysis.table.rank': 'Rank',
  'dashboard.analysis.table.search-keyword': 'Palabra clave',
  'dashboard.analysis.table.users': 'Usuarios',
  'dashboard.analysis.table.weekly-range': 'Rango Semanal',

  'dashboard.Workplace.user-volume': 'User Volume',
  'dashboard.Workplace.user-yesterday': 'User Yesterday',
  'dashboard.Workplace.user-week': 'User Week',
  'dashboard.Workplace.user-trend': 'User Trend',
  'dashboard.Workplace.metrical-data': 'Metrical Data',
  'dashboard.Workplace.metrical-yesterday': 'Metrical Yesterday',
  'dashboard.Workplace.metrical-week': 'Metrical Week',
  'dashboard.Workplace.metrical-trend': 'Metrical Trend'
}
