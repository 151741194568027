export default {
  'user.login.userName': 'usuario',
  'user.login.password': 'contraseña',
  'user.login.username.placeholder': 'usuario: admin o user',
  'user.login.password.placeholder': 'contraseña: ant.design',
  'user.login.message-invalid-credentials': 'error de cuenta o contraseña',
  'user.login.message-invalid-verification-code':
    'error de código de verificación',
  'user.login.tab-login-credentials': 'credenciales',
  'user.login.tab-login-mobile': 'Inicio de sesión de número de móvil',
  'user.login.mobile.placeholder': 'número de móvil',
  'user.login.mobile.verification-code.placeholder': 'código de verificación',
  'user.login.remember-me': 'recuérdame',
  'user.login.forgot-password': '¿Olvidaste tu contraseña?',
  'user.login.sign-in-with': 'Iniciar sesión con',
  'user.login.signup': 'Registrarse',
  'user.login.login': 'Iniciar sesión',
  'user.register.register': 'Registrarse',
  'user.register.email.placeholder': 'correo electrónico',
  'user.register.password.placeholder':
    'Por favor ingrese al menos 6 caracteres. Por favor, no utilice contraseñas que sean fáciles de adivinar.',
  'user.register.password.popover-message':
    'Por favor ingrese al menos 6 caracteres. Por favor, no utilice contraseñas que sean fáciles de adivinar.',
  'user.register.confirm-password.placeholder': 'confirme la contraseña',
  'user.register.get-verification-code': 'Obtener código de verificación',
  'user.register.sign-in': '¿Ya tienes una cuenta?',
  'user.register-result.msg': 'Cuenta：registrada en {email}',
  'user.register-result.activation-email':
    'El correo electrónico de activación se ha enviado a su correo electrónico y estará activo dentro de 24 horas. Por favor revise su correo electrónico y active su cuenta.',
  'user.register-result.back-home': 'Volver a Inicio',
  'user.register-result.view-mailbox': 'Ver buzón',
  'user.email.required': '¡Por favor ingrese su correo electrónico!',
  'user.email.wrong-format':
    '¡El correo electrónico está en un formato incorrecto!',
  'user.userName.required': '¡Por favor ingrese su nombre de usuario!',
  'user.password.required': '¡Por favor ingrese su contraseña!',
  'user.password.twice.msg':
    '¡Las contraseñas ingresadas dos veces no coinciden!',
  'user.password.strength.msg':
    'Por favor ingrese al menos 6 caracteres. Por favor, no utilice contraseñas que sean fáciles de adivinar.',
  'user.password.strength.strong': 'Fuerza: fuerte',
  'user.password.strength.medium': 'Fuerza: media',
  'user.password.strength.low': 'Fuerza: baja',
  'user.password.strength.short': 'Fuerza: demasiado corta',
  'user.confirm-password.required': '¡Por favor confirme su contraseña!',
  'user.phone-number.required': '¡Por favor ingrese su número de teléfono!',
  'user.phone-number.wrong-format': 'Formato de número de teléfono incorrecto!',
  'user.verification-code.required':
    '¡Por favor ingrese su código de verificación!',

  'user.login.platform': 'Sistema de gestión de salud VMED',
  'user.captcha.required': '¡Por favor ingrese el código de verificación!',
  'user.login.captcha.placeholder': 'Código de verificación',
  'user.register.username.placeholder': 'Nombre de usuario',
  'user.username.required': '¡Por favor ingrese su nombre de usuario!',
  'user.register.nickName.placeholder': 'Apodo',
  'user.nickName.required': '¡Por favor ingrese su apodo!'
}
