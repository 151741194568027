export default {
  'permission.role.setPermission': 'establecer permiso',
  'permission.role.newChildRole': 'nuevo rol secundario',
  'permission.role.authorityId': 'ID de autoridad',
  'permission.role.authorityName': 'nombre de autoridad',
  'permission.role.parentAuthority': 'autoridad principal',
  'permission.role.levelName': 'nivel',
  'permission.role.addRole': 'agregar rol',
  'permission.role.editRole': 'editar rol',
  'permission.role.addChildRole': 'agregar rol secundario',
  'permission.role.rootElement': 'elemento raíz',
  'permission.role.show': 'mostrar',
  'permission.role.query': 'consulta',
  'permission.role.create': 'crear',
  'permission.role.modify': 'modificar',
  'permission.role.remove': 'eliminar',
  'permission.role.export': 'exportar',
  'permission.role.menuTitle': 'título del menú',
  'permission.role.setMenu': 'establecer menú',
  'permission.role.setApi': 'establecer API',
  'permission.menu.addRoot': 'agregar raíz',
  'permission.menu.addChild': 'agregar hijo',
  'permission.menu.editChild': 'editar hijo',
  'permission.menu.name': 'nombre',
  'permission.menu.path': 'ruta',
  'permission.menu.hidden': 'oculto',
  'permission.menu.parentId': 'ID de padre',
  'permission.menu.sort': 'orden',
  'permission.menu.component': 'componente',
  'permission.menu.title': 'título',
  'permission.menu.icon': 'icono',
  'permission.menu.addMenuParameter': 'agregar parámetro de menú',
  'permission.menu.addControllBtn': 'agregar botón de control',
  'permission.menu.parameterType': 'tipo de parámetro',
  'permission.menu.parameterKey': 'clave de parámetro',
  'permission.menu.parameterValue': 'valor de parámetro',
  'permission.menu.desc': 'descripción',
  'permission.menu.isRedirect': 'redirigir',
  'permission.api.path': 'ruta',
  'permission.api.apiGroup': 'grupo de API',
  'permission.api.desc': 'descripción',
  'permission.api.method': 'método',
  'permission.api.pathMessage': 'por favor ingrese la ruta',
  'permission.api.apiGroupMessage': 'por favor ingrese el grupo de API',
  'permission.api.descMessage': 'por favor ingrese la descripción',
  'permission.api.methodMessage': 'por favor ingrese el método',
  'permission.api.addApi': 'agregar API',
  'permission.api.editApi': 'editar API',
  'permission.user.keyword': 'palabra clave',
  'permission.user.authorityId': 'ID de autoridad',
  'permission.user.startTime': 'hora de inicio',
  'permission.user.endTime': 'hora de finalización',
  'permission.user.username': 'nombre de usuario',
  'permission.user.nickName': 'apodo',
  'permission.user.phone': 'teléfono',
  'permission.user.email': 'correo electrónico',
  'permission.user.authorityName': 'nombre de autoridad',
  'permission.dictionary.addRole': 'agregar rol',
  'permission.dictionary.editRole': 'editar rol',
  'permission.dictionary.name': 'nombre',
  'permission.group.checkGroupList': 'lista de grupos de verificación',
  'permission.group.addGroup': 'agregar grupo',
  'permission.group.editGroup': 'editar grupo',
  'permission.group.id': 'Identificación de la organización',
  'permission.group.name': 'nombre',
  'permission.region.name': 'nombre',
  'permission.region.list': 'lista de regiones',
  'permission.role.deletePrompt': '¿está seguro de eliminar este rol?',
  'permission.role.level-query': 'consulta de nivel',
  'permission.region.regionID': 'ID de región',
  'permission.region.nameRequired': 'por favor ingrese el nombre',
  'permission.region.addRegion': 'agregar región',
  'permission.region.editRegion': 'editar región'
}
