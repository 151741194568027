export default {
  'order.list.id': 'ID',
  'order.list.account': 'cuenta',
  'order.list.order-no': 'número de orden',
  'order.list.type': 'tipo',
  'order.list.create-time': 'tiempo de creación',
  'order.list.pay-status': 'estado de pago',
  'order.list.deal-price': 'precio de negociación',
  'order.list.pay-time': 'tiempo de pago',
  'order.list.order-detail': 'detalle de la orden',
  'order.list.order-status01': 'pendiente de pago',
  'order.list.order-status02': 'pagado',
  'order.list.order-status03': 'cancelado',
  'order.list.order-status04': 'completado',
  'order.list.order-status05': 'pendiente de pago',
  'order.list.pay-status01': 'pendiente de pago',
  'order.list.pay-status02': 'pagado',
  'order.list.pay-status03': 'cancelado'
}
