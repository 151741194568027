export default {
  'system.list.roleName': 'Nombre del rol',
  'system.list.createTime': 'Tiempo de creación',
  'system.list.addRole': 'Agregar rol',
  'system.list.roleDesc': 'Descripción del rol',
  'system.list.creator': 'Creador',
  'system.list.member': 'Miembro',
  'system.logging.logType': 'Tipo de registro',
  'system.logging.userName': 'Nombre de usuario',
  'system.logging.name': 'Nombre',
  'system.logging.ip': 'ip',
  'system.logging.agent': 'agente',
  'system.logging.message': 'mensaje',
  'system.logging.all': 'todo',
  'system.logging.login': 'iniciar sesión',
  'system.logging.visitLogin': 'visitar iniciar sesión',
  'system.logging.logout': 'cerrar sesión',
  'system.logging.uploadTime': 'Tiempo de carga',
  'system.businessDiary.all': 'todos',
  'system.businessDiary.type01': 'Agregar usuario',
  'system.businessDiary.type02': 'Establecer permisos de usuario',
  'system.businessDiary.type03': 'Crear un rol',
  'system.businessDiary.type04': 'eliminar rol',
  'system.businessDiary.type05': 'copiar personaje',
  'system.businessDiary.type06': 'editar rol',
  'system.businessDiary.type07': 'añadir área',
  'system.businessDiary.type08': 'área de edición',
  'system.businessDiary.type09': 'borrar área',
  'system.businessDiary.type10': 'añadir organización',
  'system.businessDiary.type11': 'organización editorial',
  'system.businessDiary.type12': 'eliminar organización',
  'system.businessDiary.type13': 'Agregar datos de salud manualmente',
  'system.businessDiary.type14': 'editar datos de salud',
  'system.businessDiary.type15':
    'Agregar datos de temperatura corporal manualmente',
  'system.businessDiary.type16': 'Editar datos de temperatura corporal',
  'system.businessDiary.type17': 'editar la configuración del sistema',
  'system.businessDiary.type18': 'actualizar la información del usuario'
}
